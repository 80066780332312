import { Document } from '@contentful/rich-text-types'
import type { GetStaticPaths } from 'next'
import { slugs } from '@/constants'
import { Durations } from '@/constants/durations'
import { paths } from '@/constants/paths'
import { Seo } from '@/layout/Seo'
import { useCanonicalUrl } from '@/layout/Seo/useCanonicalUrl'
import { NextPageWithLayout } from '@/layouts'
import { ContentOnlyLayout } from '@/layouts/ContentOnlyLayout'
import { MarketingVideoPopup } from '@/organisms/MarketingVideoPopup'
import { EmailCaptureSectionCopy, getEmailCapturePageBySlug } from '@/services/EmailCapturePageService'
import { getWatchThumbnails } from '@/services/EmailCapturePageService/EmailCapturePageService'
import { Faq } from '@/services/FaqService'
import { getProjectMeta } from '@/services/ProjectsService'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { logger } from '@/utils/logging'
import { getStaticPropsErrorHandler } from '@/utils/nextUtils/nextErrorHandlers'
import { useTranslate } from '@/utils/translate/translate-client'
import { loadTranslations } from '@/utils/translate/translate-server'
import { SignUpView } from '@/views/SignUpView'

interface SignUpProps {
  slug: string
  heroImageCloudinaryPath: string
  watchAnywhereImageCloudinaryPath: string
  watchImages: {
    cloudinaryImagePath: string
    altText: string
  }[]
  openGraphTitle: string
  openGraphDescription: string
  openGraphImageCloudinaryPath: string
  subscriptionGroupId: string
  destination: string
  projectSlug: string
  projectName: string
  logoCloudinaryPath: string | null
  buttonText?: string
  sections?: EmailCaptureSectionCopy[]
  synopsis?: {
    json?: Document
  }
  synopsisTitle?: string
  youTubeUrl?: string
  faqs: Faq[]
  heroSectionCopy: EmailCaptureSectionCopy
  midSectionCopy: EmailCaptureSectionCopy
  bottomSectionCopy: EmailCaptureSectionCopy
  footerText?: string
  ctaButtonText?: string | null
  ctaButtonHref?: string | null
}

const SignUp: NextPageWithLayout<SignUpProps> = ({
  slug,
  heroImageCloudinaryPath,
  watchAnywhereImageCloudinaryPath,
  watchImages,
  openGraphTitle,
  openGraphDescription,
  openGraphImageCloudinaryPath,
  subscriptionGroupId,
  destination,
  projectSlug,
  projectName,
  logoCloudinaryPath,
  buttonText,
  synopsis,
  synopsisTitle,
  heroSectionCopy,
  midSectionCopy,
  bottomSectionCopy,
  sections = [],
  faqs,
  footerText,
  ctaButtonHref,
  ctaButtonText,
}) => {
  const { t } = useTranslate('sign-up')
  const url = useCanonicalUrl(`${paths.signup.index}/${slug}`)
  return (
    <>
      <Seo
        canonical={url}
        title={openGraphTitle}
        description={openGraphDescription}
        openGraph={{
          title: openGraphTitle,
          description: openGraphDescription,
          images: [
            {
              url: getCloudinaryImageUrl({
                path: openGraphImageCloudinaryPath,
                height: 630,
                width: 1200,
                format: 'webp',
                transforms: 'c_thumb',
              }),
              width: 1200,
              height: 630,
              alt: openGraphTitle,
            },
          ],
          url,
        }}
      />
      <SignUpView
        watchImages={watchImages}
        watchAnywhereImageCloudinaryPath={watchAnywhereImageCloudinaryPath}
        heroImageCloudinaryPath={heroImageCloudinaryPath}
        subscriptionGroupId={subscriptionGroupId}
        destination={destination}
        projectSlug={projectSlug}
        projectName={projectName}
        logoCloudinaryPath={logoCloudinaryPath}
        buttonText={buttonText}
        synopsis={synopsis}
        synopsisTitle={synopsisTitle}
        faqs={faqs}
        heroSectionCopy={heroSectionCopy}
        midSectionCopy={midSectionCopy}
        bottomSectionCopy={bottomSectionCopy}
        sections={sections}
        footerText={footerText}
      />
      <MarketingVideoPopup
        ctaHref={ctaButtonHref || '#email-capture'}
        ctaText={ctaButtonText || t('signupForUpdates', 'Sign Up for Updates')}
      />
    </>
  )
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export const getStaticProps = getStaticPropsErrorHandler(
  { filePath: 'pages/[locale]/signup/[slug].tsx' },
  async ({ federationClient, locale, next404, params, preview }) => {
    const { config, faqs } = await getEmailCapturePageBySlug({
      opts: { locale, preview },
      slug: (params?.slug as string).toLowerCase(),
    })

    if (/chosen/.test(params?.slug as string)) {
      return {
        redirect: {
          destination: '/watch/the-chosen',
          permanent: false,
        },
      }
    }

    if (params?.slug === slugs.truthAndTreasonSeries || params?.slug === slugs.truthAndConviction) {
      return {
        redirect: {
          destination: paths.signup.truthAndTreason,
          permanent: false,
        },
      }
    }

    if (!config) {
      return next404(Durations.FIVE_MINUTES_IN_SECONDS)
    }

    if (!config.watchImages) {
      config.watchImages = await getWatchThumbnails({ slug: config.projectSlug, locale: locale as string })
    }

    let logoCloudinaryPath = config.logoCloudinaryPath

    try {
      if (config.projectSlug && config.projectSlug !== 'angel-studios') {
        const result = await getProjectMeta({ slug: config.projectSlug }, federationClient, {
          locale,
          preview,
        })
        logoCloudinaryPath = result.logoCloudinaryPath ?? config.logoCloudinaryPath
      }
    } catch (e) {
      logger().error(`Error looking up project logo from hydra projectSlug`, { slug: config.projectSlug, locale }, e)
    }

    return {
      props: {
        faqs,
        slug: config.slug,
        heroImageCloudinaryPath: config.heroImageCloudinaryPath,
        watchAnywhereImageCloudinaryPath: config.watchAnywhereImageCloudinaryPath,
        watchImages: config.watchImages ?? [],
        subscriptionGroupId: config.subscriptionGroupId,
        destination: config.destination,
        openGraphTitle: config.openGraphTitle,
        openGraphDescription: config.openGraphDescription,
        openGraphImageCloudinaryPath: config.openGraphImageCloudinaryPath,
        projectSlug: config.projectSlug,
        projectName: config.projectName,
        buttonText: config.buttonText,
        synopsis: config.synopsis,
        synopsisTitle: config.synopsisTitle,
        heroSectionCopy: config.heroSectionCopy,
        midSectionCopy: config.midSectionCopy,
        bottomSectionCopy: config.bottomSectionCopy,
        sections: config.sections,
        footerText: config.footerText,
        youTubeUrl: config?.youTubeUrl,
        ctaButtonHref: config?.ctaButtonHref,
        ctaButtonText: config?.ctaButtonText,
        thumbnailUrl: config?.thumbnailUrl ?? null,
        youTubeTitle: config?.youTubeTitle ?? null,
        logoCloudinaryPath: logoCloudinaryPath ?? null,
        ...(await loadTranslations(locale, ['common', 'sign-up', 'app-promo'])),
      },
      revalidate: Durations.FIVE_MINUTES_IN_SECONDS,
    }
  },
)

SignUp.getLayout = (page) => {
  return <ContentOnlyLayout>{page}</ContentOnlyLayout>
}

export default SignUp
