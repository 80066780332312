import React from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { Button } from '@/atoms/Button'
import { ButtonVariant } from '@/atoms/Button'
import { LinkButton } from '@/atoms/Button'
import { AsP, CaptionLG, CaptionMD, TitleMD } from '@/atoms/Text'
import { Modal } from '@/molecules/Modal'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { EmailCaptureFormInput } from '@/views/SignUpView/EmailCapture/EmailCaptureFormInput/EmailCaptureFormInput'

interface EmailCaptureProps {
  className?: string
  position: string
  subscriptionGroupId: string
  destination: string
  placeholder?: string
  projectSlug: string
  projectName: string
  buttonText?: string
  buttonVariant?: ButtonVariant
  formTagline?: string
  formClassName?: string
  shouldShowSuccess: boolean
  shouldShowModal?: boolean
  taglineClassName?: string
  hideFormTagLine?: boolean
  inputClassName?: string
  buttonClassName?: string
  buttonIcon?: React.ReactNode
  buttonIconClassName?: string
  customTrackPayload?: Record<string, string>
  successButtonText?: string | null
  successButtonLink?: string | null
  successMessage?: string | null
}

export const EmailCapture: React.FC<EmailCaptureProps> = ({
  position,
  subscriptionGroupId,
  destination,
  projectSlug,
  projectName,
  placeholder,
  buttonText,
  buttonVariant = 'oxide',
  formTagline,
  formClassName,
  taglineClassName,
  hideFormTagLine,
  shouldShowSuccess,
  shouldShowModal = false,
  className,
  inputClassName,
  buttonClassName,
  buttonIcon,
  buttonIconClassName,
  customTrackPayload,
  successButtonText,
  successButtonLink,
  successMessage,
}) => {
  const { t } = useTranslate('sign-up')
  const router = useRouter()

  const handleSuccessClose = () => {
    router.replace(router.asPath.split('#')[0])
    const html = window.document.getElementsByTagName('html')[0]
    setTimeout(() => {
      html.style.height = 'initial'
      html.style.overflow = 'initial'
    }, 100)
  }

  return (
    <>
      {shouldShowSuccess ? (
        <SuccessMessage projectName={projectName} />
      ) : (
        <section className={className}>
          <EmailCaptureFormInput
            placeholder={placeholder || t('enterYourEmailAddress', 'Enter your email address')}
            destination={destination}
            projectSlug={projectSlug}
            position={position}
            subscriptionGroupId={subscriptionGroupId}
            formClassName={classNames('max-w-[640px] mx-auto', formClassName)}
            inputClassName={classNames(
              'w-full flex-auto p-4 text-gray-950 md:rounded-l-lg md:w-auto mb-4 md:mb-0',
              inputClassName,
            )}
            customTrackPayload={customTrackPayload}
          >
            <Button
              variant={buttonVariant}
              className={classNames(
                '!block w-full p-4 text-center capitalize md:w-fit md:rounded-l-none',
                buttonClassName,
              )}
            >
              {buttonIcon && <span className={buttonIconClassName}>{buttonIcon}</span>}
              {buttonText || (
                <Translate t={t} i18nKey="watchForFree">
                  Watch For Free
                </Translate>
              )}
            </Button>
          </EmailCaptureFormInput>
          {!hideFormTagLine && (
            <TitleMD
              as={AsP}
              className={classNames('text-gray-200 my-12', !taglineClassName && 'text-center', taglineClassName)}
            >
              {formTagline || (
                <Translate t={t} i18nKey="angelGuildMembersHelpFundOurShowsAndMore">
                  Angel Guild Members Help Fund Our Shows and More
                </Translate>
              )}
            </TitleMD>
          )}
        </section>
      )}
      {shouldShowModal && (
        <Modal
          className="z-50"
          backgroundOverlayClassName="!bg-black/30"
          panelClasses="!bg-core-gray-900 text-[#F5F5F4] p-8 z-100"
          action={
            <>
              <Button
                onClick={handleSuccessClose}
                variant="white"
                outline
                className="mr-4 mt-7 inline-block w-fit px-8 py-3"
              >
                {t('close', 'Close')}
              </Button>
              <LinkButton
                className="mt-7 inline-block w-fit px-8 py-3"
                href={successButtonLink ?? '/watch'}
                variant="white"
              >
                {successButtonText ?? t('browseAngelOriginals', 'Browse Angel Originals')}
              </LinkButton>
            </>
          }
          isOpen={shouldShowModal}
          title={t('youreSubscribed', `You're Subscribed!`)}
        >
          <CaptionMD className="my-8 text-left">
            {successMessage ??
              t(
                `thankYouSubscribingYouHaveBeenAddedToProject`,
                `Thank you for subscribing to updates from {{projectName}}. Keep an eye on your inbox so you don't miss the latest announcements.  In the meantime, check out other Angel Originals!`,
                { projectName },
              )}
          </CaptionMD>
        </Modal>
      )}
    </>
  )
}

const SuccessMessage = ({ projectName = 'Angel Studios' }: { projectName?: string }) => {
  const { t } = useTranslate('sign-up')

  return (
    <div className="flex items-center justify-center">
      <div className=" w-1/2 rounded-lg bg-core-gray-900 p-4 ">
        <CaptionLG color="white" className="text-center">
          <Translate t={t} i18nKey="emailSignupSuccess" values={{ projectName }}>
            You will receive updates from {{ projectName }}
          </Translate>
        </CaptionLG>
      </div>
    </div>
  )
}
